const BASE_URL = process.env.VUE_APP_API_URL;

export function apiLogin(): string {
  return BASE_URL + "login";
}
export function apiListClientPayments(): string {
  return BASE_URL + "client_payments/list";
}
export function apiClientPayments(id: string): string {
  return BASE_URL + `client_payments/${id}`;
}
export function apiPaymentMethods(): string {
  return BASE_URL + `client_payments/payment_methods`;
}
export function apiGetDocuments(id: string): string {
  return BASE_URL + `client_payments/documents/${id}`;
}
export function apiGetAgencias(): string {
  return BASE_URL + `client_payments/agencies`;
}
export function apiGetMessages(formId: string): string {
  return BASE_URL + `client_payments/messages/${formId}`;
}
export function apiGetFilters(): string {
  return BASE_URL + "client_payments/filters";
}
export function apiGenerarRecibo(formId: string): string {
  return BASE_URL + `${formId}`;
}
export function apiPostAviso(): string {
  return BASE_URL + `client_payments`;
}
export function apiPatchAviso(formId: string): string {
  return BASE_URL + `client_payments/${formId}`;
}
export function apiGetDocumentsFromForm(formId: string): string {
  return BASE_URL + `client_payments/payment-documents/${formId}`;
}
export function apiGenerarSige(formId: string): string {
  return BASE_URL + `client_payments/generate-sige-document/${formId}`;
}

export function apiDescargarSige(params: string): string {
  return BASE_URL + `client_payments/sige-document/${params}`;
}

export function apiGetComprobantePago(idFile: string): string {
  return BASE_URL + `client_payments/payment-file/${idFile}`;
}

export function apiGetReciboPdf(formId: string): string {
  return BASE_URL + `client_payments/recibo-sige-pdf/${formId}`;
}
