
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { apiLogin } from "@/router/endpoints";

import TheHeader from "@/components/TheHeader.vue";
import LoginInput from "@/components/LoginInput.vue";
import LoadingIcon from "@/assets/icons/LoadingIcon.vue";
import router from "@/router/index";

export default defineComponent({
  name: "Login",
  components: {
    TheHeader,
    LoginInput,
    LoadingIcon,
  },
  setup() {
    const store = useStore();
    let user = ref("");
    let password = ref("");
    let error = ref("");
    let loading = ref(false);

    if (store.getters.isAuthenticated) {
      router.push("Home");
    }

    const login = () => {
      error.value = "";
      loading.value = true;

      const loginData = {
        username: user.value,
        password: password.value,
        deposit: 1
      };

      axios
        .post(apiLogin(), loginData)
        .then((res) => {
          let token = res.data.access_token;
          store.commit("LOGIN_SUCCESS", {
            token: token,
            user: user.value,
          });
          router.push("Home");
        })
        .catch((err) => {
          error.value = Object.values(err.response.data).toString();
        })
        .finally(() => {
          loading.value = false;
        });
    };
    return { user, password, error, login, loading };
  },
});
