<template>
  <div class="pt-12 h-full">
    <the-header />
    <div class="grid grid-cols-5 h-full">
      <div class="hidden sm:block col-span-2 h-full overflow-hidden">
        <img
          src="@/assets/login_sideimg.jpg"
          class="h-full w-full object-top object-cover"
        />
      </div>
      <div class="col-span-5 sm:col-span-3 p-5">
        <form
          class="
            h-full
            w-2/3
            max-w-sm
            mx-auto
            pt-10
            sm:pt-0
            flex flex-col
            justify-start
            items-center
            sm:justify-center
            gap-y-10
            md:gap-y-16
          "
          @submit.prevent="login"
        >
          <p v-if="error" class="text-red-500">{{ error }}</p>
          <login-input
            :type="'user'"
            :id="'user'"
            :placeholder="'Usuario'"
            autocomplete="on"
            v-model:value="user"
          />
          <login-input
            :type="'password'"
            :id="'password'"
            :placeholder="'Clave'"
            autocomplete="on"
            v-model:value="password"
          />

          <div class="w-full space-y-2">
            <button
              :class="[
                { 'bg-gray-700 cursor-default': loading },
                { 'bg-main-blue hover:bg-secondary-blue': !loading },
                'w-full h-12 py-3 px-8 text-xl tracking-wide flex items-center justify-center text-white rounded-md transition duration-300',
              ]"
              :disabled="loading"
              type="submit"
            >
              <loading-icon v-if="loading" />
              <span v-else> INGRESAR </span>
            </button>
            <p class="hidden">
              <a class="text-sm text-main-blue" href="">Olvidé mi clave</a>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { apiLogin } from "@/router/endpoints";

import TheHeader from "@/components/TheHeader.vue";
import LoginInput from "@/components/LoginInput.vue";
import LoadingIcon from "@/assets/icons/LoadingIcon.vue";
import router from "@/router/index";

export default defineComponent({
  name: "Login",
  components: {
    TheHeader,
    LoginInput,
    LoadingIcon,
  },
  setup() {
    const store = useStore();
    let user = ref("");
    let password = ref("");
    let error = ref("");
    let loading = ref(false);

    if (store.getters.isAuthenticated) {
      router.push("Home");
    }

    const login = () => {
      error.value = "";
      loading.value = true;

      const loginData = {
        username: user.value,
        password: password.value,
        deposit: 1
      };

      axios
        .post(apiLogin(), loginData)
        .then((res) => {
          let token = res.data.access_token;
          store.commit("LOGIN_SUCCESS", {
            token: token,
            user: user.value,
          });
          router.push("Home");
        })
        .catch((err) => {
          error.value = Object.values(err.response.data).toString();
        })
        .finally(() => {
          loading.value = false;
        });
    };
    return { user, password, error, login, loading };
  },
});
</script>