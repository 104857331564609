
import { defineComponent } from "vue";
import store from "@/store/index";
import router from "@/router/index";

export default defineComponent({
  props: {
    isPanel: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  setup() {
    const FELI_SITE = process.env.VUE_APP_FELI_SITE;
    const isAuthenticated = store.getters.isAuthenticated;
    const user = store.getters.user;
    const logout = () => {
      store.commit("DESTROY_TOKEN");
      router.push({ name: "Login" });
    };

    return { FELI_SITE, isAuthenticated, user, logout };
  },
});
