<template>
  <div
    class="
      w-full
      h-12
      p-1
      fixed
      top-0
      flex
      items-center
      bg-white
      border-b border-gray-300
      gap-x-6
      font-barlow
      z-50
      px-2
      sm:px-9
    "
  >
    <div v-if="isPanel" @click="$emit('toggleMenu')" class="block md:hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 hover:text-main-blue cursor-pointer transition duration-300"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
    </div>
    <div class="h-full my-2 flex justify-center">
      <img alt="Feli logo" src="../assets/logo.png" class="h-full w-auto" />
    </div>
    <div class="hidden xs:flex flex-1 justify-start">
      <h1 class="font-semibold text-title text-sm sm:text-xl">
        Admin
      </h1>
    </div>
    <div class="hidden mobile:block px-4 text-xs">
      <a :href="FELI_SITE"> Sitio web </a>
    </div>
    <div
      class="flex flex-1 xs:flex-none justify-end xs:justify-center items-center gap-x-1 text-gray-600"
      v-if="isAuthenticated"
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="hidden mobile:block h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <p>{{ user }}</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="
          h-5
          w-5
          ml-2
          hover:text-main-blue
          transition
          duration-300
          cursor-pointer
        "
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        @click="logout"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import store from "@/store/index";
import router from "@/router/index";

export default defineComponent({
  props: {
    isPanel: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  setup() {
    const FELI_SITE = process.env.VUE_APP_FELI_SITE;
    const isAuthenticated = store.getters.isAuthenticated;
    const user = store.getters.user;
    const logout = () => {
      store.commit("DESTROY_TOKEN");
      router.push({ name: "Login" });
    };

    return { FELI_SITE, isAuthenticated, user, logout };
  },
});
</script>


<style>
</style>